import React, { useEffect, useRef, useState } from 'react'
import DocumentTitle from '../../components/DocumentTitle/DocumentTitle'
import CardTableAE from '../../components/CardTableAE/CardTableAE'
import Api from '../../services/Api'
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { loadingState } from '../../recoil/atoms/loadingAtom';
import { ToWords } from 'to-words';
import Erreurs from '../../components/Erreurs/Erreurs';
import moment from 'moment/moment';
import { formatStringNumber } from '../../services/Helpers/fonctions';
import { useReactToPrint } from 'react-to-print';

function PaymentDetailsOrganisation() {
  const api = new Api();
  const { id } = useParams();
  const [isLoading, setIsLoading ] = useRecoilState(loadingState);
  const [ paiementData,setPaiementData ] = useState(null);
  const [ erreurs,setErreurs ] = useState([]);
  const url = process.env.REACT_APP_URL.replace('api/','') + 'storage/';
  const toWords = new ToWords({
    localeCode: 'fr-FR',
    converterOptions: {
      currency: false,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
    }
  });
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const componentRef = useRef();
  const getPaiement = async (id) => {
    setIsLoading(true);
     const { status,paiement,messages } = await api.apiData("get",`/paiement_organisation/getPaiementByRefeference/${id}`);
    setIsLoading(false);
    if( status === 200 ){
      if(paiement){
        setPaiementData(paiement);
      }
    }else setErreurs(messages);
    // console.log(paiement)
  }
  useEffect(() => {
    getPaiement(id)
  },[id])

  return (
    <div className="payment-invoice page">
       <DocumentTitle title="Details de paiement" />
        <Erreurs validator={erreurs} /> 
        {( paiementData && erreurs.length === 0) &&
         <>
          <div className="buttons-group space-between">
            <div className="left">
              <button className="print-btn"  onClick={handlePrint}>
                 Imprimer
             </button>
             
            </div>  
          </div> 
          <div className="invoice" ref={componentRef}>
          <div className="head">
             <h1>Reçu de Paiement </h1>
             <div className="logo"><img src={ url + paiementData.logo } style={{width:'85px'}}/></div>
          </div>
          <div>
            <div style={{display:'inline'}}>
                <span style={{fontSize:'17px',fontFamily:'sans-serif',fontStyle:'italic'}}>Numéro de Référence : </span> 
                <span style={{fontWeight:'bold',fontFamily:'revert-layer'}}>{paiementData.reference}</span>
            </div> 

          </div> 
          <div className="bill-details">
            <div className="from">
                <span className="subtitle">Par </span>
                <strong>DSD Guinée</strong>
                <p>Agence de {paiementData.nom_agence}</p>
                <p><span>Prefecture de </span> <span className='gras'>{paiementData.prefecture}</span></p>
                <p>Commune de  <span className='gras'>{paiementData.commune}</span><span className='gras'></span></p>
                <p>Agent : <strong>{ paiementData.username }</strong></p>
            </div>
            <div className="to">
              <span className="subtitle">Client </span>
              <span>Type : <strong>{ paiementData.TypeOrganisation}</strong></span>
              <span>Organisation : <strong>{ paiementData.organisation }</strong></span>
              <span>Téléphone : <strong>{ paiementData.telephone }</strong></span>   
            </div>  
            <div className="details">
              <span className="subtitle">Détails </span>
              <div>
                <span>Num. Chassis : </span>
                <strong>{ paiementData.chassis }</strong>
              </div>
              <div>
                <span>Mode Immat. :</span>
                <strong>{ paiementData.typeOperation }</strong>
              </div>
              <div>
                <span>Date d'émission : </span>
                <strong>{ moment().format("Do MMMM YYYY")}</strong>
              </div>
            </div>
          </div> 
           <div className="array">
             <CardTableAE data={paiementData} /> 
           </div>
            <div style={{display:'flex',flexDirection:'column',alignItems:'flex-end'}}>
             <div style={{paddingBottom:'10px'}}>
                <span>Total : <strong>{formatStringNumber(paiementData.prix)} </strong>fg</span>
             </div>
             <div><strong>{toWords.convert(paiementData.prix)}</strong> Francs Guinéens</div>
            </div>
            <div className="signature">
              <div>
                <p>Signature de l'agent</p>
              </div>
            <div>
              <p>Signature du client</p>
          </div>
          <div className='qr'>
            <p>QR Code</p>
            <div className='qr-image'>
              <img src={url+paiementData?.qrcodepath}/>
            </div>
          </div>
        </div> 
          </div>
         </> 
         }       
    </div>
  )
}

export default PaymentDetailsOrganisation