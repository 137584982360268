import React, { useState } from 'react'
import CardTableCMC from '../CardTableCMC/CardTableCMC'
import { useRecoilState } from 'recoil';
import { loadingState } from '../../recoil/atoms/loadingAtom';
import Api from '../../services/Api';
import Erreurs from '../Erreurs/Erreurs';
import { objecttoFormData } from '../../services/Helpers/fonctions';

function StepTwo({ paiement,setPaiement,setActiveStep,activeStep,prevStep,nextStep,categories }) {
  const [ isLoading,setIsLoading ] = useRecoilState(loadingState);
  const api = new Api();
  const [ erreurs , setErreurs ] = useState([]);

  const payer = async () => {
      var formdata = objecttoFormData(paiement);
      setErreurs([]);setIsLoading(true);
         const { status,errors,data } = await api.apiData("post","/paiement/cmc/nouveau",formdata);
      setIsLoading(false);
      if( status === 200){
        setPaiement(data);
        nextStep();
        }
       else   
         setErreurs(errors);
  }
 
  return (
    <div className='step-component step-two'>
       <h4>Paiement de cartificat de mise en circulation</h4>
       <div className="table">
         <CardTableCMC 
           paiement={paiement}
           categories={categories}
         />
       </div> 
       <Erreurs validation={erreurs} />
       <div className="buttons">
        <button className="secondary" onClick={prevStep}>
          Précedent
        </button>
        <button className="primary" onClick={payer}>
          Payer
        </button>
      </div>
    </div>
  )
}

export default StepTwo