import React, { useContext, useEffect, useState } from 'react'
import { ElementContext } from '../../services/Context/Context';
import { formatStringNumber } from '../../services/Helpers/fonctions';

function CardTableAE({data}) {
  const [ typeOrganisation , setTypeOrganisation ] = useState('');
  const [ organisation,setOrganisation ] = useState('');
  const [ categorie,setCategorie ] = useState("");
  const { elementsData } = useContext(ElementContext);

  useEffect(() => {
        if(typeof elementsData?.typeOrganisations !== 'undefined' 
          && typeof elementsData?.Organisations !== 'undefined'
          && typeof elementsData?.categories !== 'undefined'
          ){
        setTypeOrganisation(elementsData?.typeOrganisations.filter((p) => p.typeOrganisation_id == data.typeOrganisation_id)[0]);
        setOrganisation(elementsData.Organisations.filter((p) => p.type_organisation_id == data.organisation_id)[0]);
        setCategorie(elementsData.categories.filter((p) => p.categorie_id == data.categorie_id)[0]);
        }
        
     
  },[data,elementsData?.typeOrganisations]);
 
  return (
    <div className="cart-table">
        <table>
             <thead className="table-header">
             <tr key={Math.random()}>
             <th>Type Organisation</th>
             <th>Organisation</th>
             <th>Catégorie</th>
             <th>Prix (en fg)</th>
            </tr>
        </thead>
        <tbody>
          <tr>
            <td>{ typeOrganisation.nom }</td>
            <td>{ organisation.nom }</td>
            <td>{ categorie?.nomCategorie }</td>
            <td>{ formatStringNumber(data?.prix) }</td>
          </tr>
        </tbody> 
        </table>
    </div>
  )
}

export default CardTableAE