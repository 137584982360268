import React, { useContext, useEffect, useRef } from 'react'
import { ElementContext } from '../../services/Context/Context'
import { useForm } from "react-hook-form";
import "./_Step.scss"
import { FirstUpperCase } from '../../services/Helpers/fonctions';
function StepOne({ setActiveStep , activeStep ,nextStep,paiement,setPaiement,setCategories }) {
  const { elementsData } = useContext(ElementContext);

  const { categories } = elementsData ? elementsData : [] ;
  const { register,handleSubmit,reset,formState: { errors },} = useForm({defaultValues: {paiement},});
  const handleInput = (e) => {
    const re = /^[0-9\b]+$/;
    if( e.target.name === "telephoneClient" ){
      if( (e.target.value === '' || re.test(e.target.value)) && e.target.value.length <= 9 ){
        setPaiement({...paiement,[e.target.name]:e.target.value});
     }
    }
    else{
      if(e.target.name === "type_client") {
        if( e.target.value === "Personnel"){
           setPaiement({...paiement, nom_societe: '' });
         }
         else if( e.target.value === "Société ou Entreprise"){
          setPaiement({...paiement, prenomClient: '' ,nomClient:''});
         }
      } 
      setPaiement({...paiement,[e.target.name]:e.target.value });
    }
  

  }
  useEffect(() => {
    setCategories(categories);
  },[elementsData?.agences])
  return (
    <div className='step-component'>
        <h4>Informations du client</h4>
       <form onSubmit={handleSubmit(nextStep)}>
          <div className='input-group'>
            <label className='obligatoire'>
              Type de client
              <select
                value={paiement.type_client} 
                name="type_client"
                {...register("type_client", {
                onChange: (e) => {
                  handleInput(e);
                },
                validate: (value) => value !== "",
                 })}
              >
                <option value="">---</option>
                <option value="Personnel">Personnel</option>
                <option value="Société ou Entreprise">Société ou Entreprise</option>
              </select>
              {errors.type_client && (
                 <span className="error-msg">Ce champ est obligatoire.</span>
              )} 
            </label>
           { paiement.type_client === "Personnel" &&
             <>
              <label className='obligatoire'>
                  Prénom  
                  <input 
                  value={FirstUpperCase(paiement.prenomClient)}
                  type="text"
                  name="prenomClient"
                  autoFocus
                  placeholder="Prénom du client"
                  id="prenomClient"
                  {...register("prenomClient", {
                    onChange: (e) => {
                      handleInput(e);
                    },
                    required: true,
                    maxLength: 150,
                    minLength: 2,
                  })}
                />
                {errors.prenomClient && errors.prenomClient?.type === "required" && (
                  <span className="error-msg">Ce champ est obligatoire.</span>
                )}
                {errors.prenomClient && errors.prenomClient?.type === "minLength" && (
                  <span className="error-msg">
                    Ne peut pas être inférieur à 2 caractères.
                  </span>
                )}
                {errors.prenomClient && errors.prenomClient?.type === "maxLength" && (
                  <span className="error-msg">
                    Ne peut pas être supérieur à 150 caractères.
                  </span>
                )}
              </label> 
              <label className='obligatoire'>
                  Nom 
                  <input
                    value={paiement.nomClient }
                    type="text"
                    name="nomClient"
              
                    placeholder="Nom du client"
                    id="nomClient"
                    {...register("nomClient", {
                      onChange: (e) => {
                        handleInput(e);
                      },
                      required: true,
                      maxLength: 150,
                      minLength: 2,
                    })}
                />
                {errors.nomClient && errors.nomClient?.type === "required" && (
                  <span className="error-msg">Ce champ est obligatoire.</span>
                )}
                {errors.nomClient && errors.nomClient?.type === "minLength" && (
                  <span className="error-msg">
                    Ne peut pas être inférieur à 2 caractères.
                  </span>
                )}
                {errors.nomClient && errors.nomClient?.type === "maxLength" && (
                  <span className="error-msg">
                    Ne peut pas être supérieur à 150 caractères.
                  </span>
                )}
              </label>
             </>
            }
           { paiement.type_client === "Société ou Entreprise" &&
            <label className='obligatoire'>
              Société ou Entreprise  
            <input autoFocus
            value={FirstUpperCase(paiement.nom_societe)}
            type="text"
            name="nom_societe"
      
            placeholder="Désignation de la société ou de l'entreprise"
            id="nom_societe"
            {...register("nom_societe", {
              onChange: (e) => {
                handleInput(e);
              },
              required: true,
              maxLength: 150,
              minLength: 2,
            })}
          />
          {errors.nom_societe && errors.nom_societe?.type === "required" && (
            <span className="error-msg">Ce champ est obligatoire.</span>
          )}
          {errors.nom_societe && errors.nom_societe?.type === "minLength" && (
            <span className="error-msg">
              Ne peut pas être inférieur à 2 caractères.
            </span>
          )}
          {errors.nom_societe && errors.nom_societe?.type === "maxLength" && (
            <span className="error-msg">
              Ne peut pas être supérieur à 150 caractères.
            </span>
          )}
        </label> 
           }
          </div>
          <div className='input-group'>
             <label className='obligatoire'>
                Type d'engin
                <select 
                    value={paiement.type_engin} 
                    name="type_engin"
                    {...register("type_engin", {
                      onChange: (e) => {
                        handleInput(e);
                      },
                      validate: (value) => value !== "",
                      })}
                   >
                  <option value="">---</option>
                  {categories?.length > 0  
                    && categories.map((categorie) => (<option value={categorie.categorie_id} key={categorie.categorie_id}>     {categorie.nomCategorie}</option>))
                    }

                </select>
                {errors.type_engin && (
                 <span className="error-msg">Ce champ est obligatoire.</span>
               )}
             </label>
             <label className='obligatoire'>
                chassis
                <input
                   type="text"
                   value={paiement.chassis}
                   autoFocus
                   placeholder="Numéro de chassis"
                   name="chassis"
                   id="chassis"
                   {...register("chassis", {
                    onChange: (e) => {
                      handleInput(e);
                    },
                    required: true,
                    maxLength: 17,
                    minLength: 2,
                  })}
            />
            {errors.chassis && errors.chassis?.type === "required" && (
              <span className="error-msg">Ce champ est obligatoire.</span>
            )}
            {errors.chassis && errors.chassis?.type === "minLength" && (
              <span className="error-msg">
                Ne peut pas être inférieur à deux caractères.
              </span>
            )}
            {errors.chassis && errors.chassis?.type === "maxLength" && (
              <span className="error-msg">
                Ne peut pas être superieur à dix sept (17).
              </span>
            )}
             </label>
          </div>
          <div className='input-group'>
            <label className='obligatoire'>
                Téléphone  
                <input
                type="text"
                placeholder="Numéro de téléphone du client(ex: 620000000)"
                name="telephoneClient"
                id="telephoneClient"
                value={ paiement.telephoneClient }
                {...register("telephoneClient", {
                  onChange: (e) => {
                    handleInput(e);
                  },
                  required: true,
                  maxLength: 9,
                  minLength: 5,
                  pattern: /6[0-9]{8}$/g,
                })}
              />
              {errors.telephoneClient && errors.telephoneClient?.type === "required" && (
                <span className="error-msg">Ce champ est obligatoire.</span>
              )}
              {errors.telephoneClient && errors.telephoneClient?.type === "minLength" && (
                <span className="error-msg">
                  Ne peut pas être inférieur à 5 caractères.
                </span>
              )}
              {errors.telephoneClient && errors.telephoneClient?.type === "maxLength" && (
                <span className="error-msg">
                  Ne peut pas être supérieur à 9 caractères.
                </span>
              )}
              {errors.telephoneClient && errors.telephoneClient.type === "pattern" && (
                  <span role="alert" className="error-msg">
                    Format invalide.ex: 620000000
                  </span>
              )}
            </label>
            <label> 
                Email  
                <input type='text' name="emailClient" placeholder='Email (ex:example@example.com)' 
                  value={ paiement.emailClient }
                  {...register("emailClient", {
                    onChange: (e) => {
                      handleInput(e);
                    },
                    required: false,
                    // maxLength: 9,
                    // minLength: 5,
                  pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                  })}
               />
              {errors.emailClient && errors.emailClient.type === "pattern" && (
                <span role="alert" className="error-msg">
                  Format invalide.ex: example@example.com
               </span>
              )}
            </label>
           </div>
           <div className="buttons">
             <button type="submit">Suivant</button>
          </div>
       </form>     
    </div>
  )
}

export default StepOne