import React, { useEffect, useState } from 'react'
import CardTableAE from '../CardTableAE/CardTableAE'
import { objecttoFormData } from '../../services/Helpers/fonctions';
import Api from '../../services/Api';
import { useRecoilState } from 'recoil';
import { loadingState } from '../../recoil/atoms/loadingAtom';
import Erreurs from '../Erreurs/Erreurs';

function StepTwo({nextStep,prevStep,paiementData,setPaiementData}) {
  const api = new Api();
  const [isLoading,setIsLoading] = useRecoilState(loadingState);
  const [ erreurs,setErreurs ] = useState([]);
  useEffect(() => {
    if( paiementData.categorie_id == 1 )
          setPaiementData({...paiementData ,prix:300000});
    else if( paiementData.categorie_id == 2 )
       setPaiementData({...paiementData ,prix:400000});
    else if( paiementData.categorie_id == 3 )
       setPaiementData({...paiementData ,prix:420000});
  },[]) 
  const payer = async () => {
    setIsLoading(true);
      var formdata = objecttoFormData(paiementData);
      const { status, messages, data } = await api.apiData("post","/paiement_organisation/new",formdata);
      if( status !== 200 ){
         setErreurs(messages);
      }else {
        setPaiementData({...paiementData,qrpath: data.qrpath,reference:data.reference});
        nextStep();
      }
    setIsLoading(false);  
    //  nextStep(); 
  }  
  return (
    <div className="step-component step-two">
      <Erreurs validation={erreurs} />
      <div className="table">
        <CardTableAE data={paiementData}/>
      </div>
      <div className="buttons">
        <button className="secondary" onClick={prevStep}>
          Précedent
        </button>
        <button className="primary" onClick={payer}>
          Payer
        </button>
      </div>
    </div>    
  )
}

export default StepTwo