import React, { useEffect, useState } from 'react'
import DocumentTitle from '../../components/DocumentTitle/DocumentTitle'
import "./Listechassisdouane.scss";
import { DataGridPremium, GridToolbar, frFR } from "@mui/x-data-grid-premium";
import Box from "@mui/material/Box";
import * as XLSX from 'xlsx';
import moment from 'moment';
import { useRecoilState } from 'recoil';
import { loadingState } from '../../recoil/atoms/loadingAtom';
import Api from '../../services/Api';
import Erreurs from '../../components/Erreurs/Erreurs';


function Listechassisdouane() {
  const [cmcdata,setCmcdata ] = useState([]);  
  const [ totalChassis,setTotalChassis ] = useState(0);
  const [ seriesChassis,setSeriesChassis ] = useState([]);
  const [ isLoading,setIsLoading ] = useRecoilState(loadingState);
  const [erreurs,setErreurs ] = useState();
  
  const[ pageState,setPageState ] = useState({
      data:[],
      total: 0,
      page: 1,
      pageSize: 10 
  })

  const api = new Api();
  const columns = [
    {
        field: "ordre",
        headerName: "N°",
        minWidth: 80,
    },
    {
        field:"chassis",
        headerName:"Chassis",
        minWidth:180
    },
    {
        field:"identifiant",
        headerName:"Identifiant",
        minWidth:140
    },
    {
        field:'type',
        headerName: "Type d'engin",
        minWidth:180
    },
    {
        field:'marque',
        headerName: "Marque",
        minWidth:180
    },
    {
        field:'date',
        headerName: "Date",
        minWidth:150,
        flex:1
    }
  ]
  const uploadFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
        const data = e.target.result;
        const formatOfData = {
            chassis: '',
            id: '',
            type_engin: '',
            marque: '',
            filename: '',
            date:''
         } 
         setTotalChassis(0);setSeriesChassis([]);
        if (file.name.endsWith('.csv')) {
            // Parse CSV data
            const arr = []; 
            //let num = 0;
            
            const dataArray = data.split('\n').map((row) => {
                setTotalChassis((t) => t + 1 );              
                return row.split(';');
            });
            if( dataArray.length > 1 )
               setSeriesChassis(dataArray.map((item) => {
                  return {
                     chassis : item[0],
                     id: item[1],
                     type_engin: item[4],
                     marque: item[3],
                     filename: file.name,
                     date: item[5]?.replace('\r\r','')
                  }
            }));

            
        } else if (file.name.endsWith('.xls') || file.name.endsWith('.xlsx') || file.name.endsWith(".csv")) {
            // Parse Excel data
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);
            const arr = []; 
            if ( typeof jsonData === 'object' ){
               jsonData.forEach((item,index) => {
                setTotalChassis((t) => t + 1 )
                 if( Object.keys(item).length > 0 ){
                    Object.keys(item).map((key,index) => {
                        if( index === 0 ){
                            formatOfData['chassis'] = item[key];
                        }
                        else if(index === 1 ){
                            formatOfData['id'] = item[key];
                        }
                        else if( index === 3){
                            formatOfData['marque'] = item[key];
                        }
                        else if( index === 4){
                            formatOfData['type_engin'] = item[key];
                        } 
                        else if( index === 5){
                    
                            formatOfData['date'] = moment(key).format('YYYY-MM-DD HH:mm:ss');
                        } 
                    });
                    formatOfData['filename'] = file.name;
                    arr.push(formatOfData)
                 }
               }) 
            }
            setSeriesChassis(arr);
        } else {
            console.error('Unsupported file format');
        }
     };

    reader.readAsBinaryString(file);

  };
  const loadadata = async (e) => {
    e.preventDefault();
    setIsLoading(true);setErreurs([]);
      var formdata = seriesChassis.filter((p) => p.chassis !== '');
      const { status,messages } = await api.apiData("post","/paiement/cmc/chassis/douane",formdata);
      if( status === 200 ){
         setPageState((prev) => ({...prev,page:1}))
      }
      else {
          
         setErreurs(messages);
      }
    setIsLoading(false)
  }
  const getData = (data) => {
    setCmcdata([]);
   
    if( data?.data?.length > 0 ){
       return data.data.map((item,index) => {

            return {
                id: item.id,
                ordre: data.from + index,
                identifiant: item.identifiant,
                type: item.type_engin,
                marque:item.marque,
                chassis: item.chassis,
                date: moment(item.created_at).format("Do MMMM YYYY")  
            }
        });
    }
    return [];
  }
  const getInitialData = async () => {
    setErreurs([]);setIsLoading(true);
      const { status,messages, data } = await api.apiData("get",`/dsd/douane/paiementscmc?pageSize=${pageState.pageSize}&currentPage=${pageState.page}`);
     
      //console.log(data)
  
    setIsLoading(false);
    if( status === 200 ){
       if( pageState.page <= data.last_page)
         setPageState( old => ({...old,data:getData(data),total:data.total }))
    }
    else{
        setErreurs(messages);
    }
   // setIsLoading(false)
   // console.log(data)
  }
  useEffect(() => {
    getInitialData()
  },[pageState.pageSize,pageState.page]);

  return (
    <div className='payment-list page paiement-listdouane'>
         <DocumentTitle title="Liste des CMC de la douane" />
         <div className='header'>
              <h3>Liste des véhicules dédouanés</h3>
              <div className='file-uplodader-container'>
                <div className='file-component'>
                    <label htmlFor="file-upload" className="custom-file-upload">
                        Téléverser le fichier de la douane
                    </label>
                    <input id="file-upload" type="file" onChange={uploadFile}/>
                </div>
                <div>
                    <button className={`secondary _btn  ${seriesChassis.length === 0  ? "_disabled":""}`}
                    onClick={loadadata}
                    > Charger dans la BD</button>
                </div>
                <div className='total-data'>
                    <span> {totalChassis > 0 ? totalChassis - 1 : 0} Chassis</span>
                </div>
              </div>
             
         </div>
         <Erreurs validation={erreurs} />
         <div className="array">
          <Box sx={{ height: 579, width: "100%" }}>
            <DataGridPremium
              sx={{ borderRadius: 0 }}
              density="compact"
              components={{ Toolbar: GridToolbar ,}}
              rows={pageState.data}
              columns={columns}
              rowCount={pageState.total}
              autoPageSize
              pagination
              page={pageState.page - 1 }
              disableSelectionOnClick
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              slots={{ toolbar: GridToolbar }}
              paginationMode='server'
           
              onPageSizeChange={(newpageSize, details ) => {
                // Maybe save into state
                // setInitialPageSize(newpageSize)
                setPageState((prev) => ({...prev,pageSize:newpageSize}))
              }}
              onPageChange={(newPage) => {
                setPageState(prev => ({ ...prev, page: newPage + 1 }))
              }}
              />
          </Box>
        </div>      
    </div>
  )
}

export default Listechassisdouane