import React, { useState } from 'react'
import DocumentTitle from '../../components/DocumentTitle/DocumentTitle'
import Stepper from '../../components/Stepper/Stepper';
import { paymentStepsCMC } from '../../utils/texts';
import StepOne from '../../components/PaiementCmcStep/StepOne';
import StepTwo from '../../components/PaiementCmcStep/StepTwo';
import StepThree from '../../components/PaiementCmcStep/StepThree';

function PaiementCmc() {
  const [ activeStep,setActiveStep ] = useState(1);
  const [ paiement,setPaiement ] = useState({
    type_engin: '',
    chassis: '',
    telephoneClient: '',
    prenomClient: '',
    nomClient: '',
    emailClient:'',
    type_client:'',
    nom_societe: ''

  });
  const [ categories,setCategories ] = useState([]);
  const nextStep = () => {
    if( activeStep <= 3 )
      setActiveStep((s) => s + 1)
  }  
  const prevStep = () => {
    if( activeStep >= 1 ){
        setActiveStep((s) => s - 1);
    }
  } 

  return (
    <div className='payment page'>
       <DocumentTitle title="Nouveau paiement CMC" />
        <div className="header">
            <h3>Paiement de Certificat de Mise en Circulation(CMC)</h3>
            <p>Suivez les trois étapes suivantes pour faire un nouveau paiement.</p>
        </div>
        <div className="payment-zone">
            <div className="payment-steps">
              <Stepper steps={paymentStepsCMC} activeStep={activeStep} />
            </div>
            <div className="payment-form">
              { activeStep === 1 && 
                <StepOne 
                 setActiveStep={ setActiveStep }
                 activeStep={ activeStep }
                 nextStep={nextStep}
                 paiement={paiement}
                 setPaiement={setPaiement}
                 setCategories={setCategories}
                />
              }
              { activeStep === 2 && 
                <StepTwo 
                 setActiveStep={ setActiveStep }
                 activeStep={ activeStep }
                 paiement={paiement}
                 setPaiement={setPaiement}
                 prevStep={prevStep}
                 nextStep={nextStep}
                 categories= {categories}
                />
               }
              { activeStep === 3 && 
                <StepThree 
                 setActiveStep={ setActiveStep }
                 activeStep={ activeStep }
                 paiement={paiement}
                />
               }
            
            </div>
       </div> 
    </div>
  )
}

export default PaiementCmc