import React, { useEffect, useState } from 'react'
import { formatStringNumber } from '../../services/Helpers/fonctions'

function CardTableCMC({paiement,categories}) {
    const [ categorie,setCategorie ] = useState('');
   
    useEffect(() => {
      const categorieArray = categories.filter((c) => c.categorie_id == paiement.type_engin);
      if( categorieArray.length > 0 )
         setCategorie(categorieArray[0].nomCategorie);

    },[paiement]);
   //console.log(paiement) 
    return (
        <div className='cart-table'>
         {paiement && 
            <table style={{ width:'100%'}}>
                <thead className="table-header">
                    <tr>
                      <th>Type d'engin</th>
                      {paiement.type_client === "Personnel" && <th>Nom et Prénom du client</th>}
                      {paiement.type_client === 'Société ou Entreprise' && <th> Nom de l'entité</th>}
                      <th>Téléphone</th>
                      <th style={{ textAlign:'end'}}>Prix (en fg)</th>
                    
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{ categorie }</td>
                        {paiement.type_client === "Personnel" && <td>{paiement.prenomClient + " "+ paiement.nomClient}</td>}
                        {paiement.type_client === "Société ou Entreprise" && <td>{paiement.nom_societe }</td>}
                        <td>{ paiement.telephoneClient }</td>
                        <td style={{ textAlign:'end'}}> {formatStringNumber("100000") } </td>
                    </tr>
                    <tr>
                      <td>Commission</td>
                      <td colSpan={3} style={{ textAlign:'end',marginRight:'40px'}}>{formatStringNumber("0") }</td>
                    </tr>
                </tbody>
            </table> 
            }  
        </div>
      )
}

export default CardTableCMC