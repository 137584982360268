import React, { useEffect, useRef, useState } from 'react'
import "./PaymentCMCDetails.scss";
import { useContext } from 'react';
import { ElementContext, UserContext } from '../../services/Context/Context';
import { useReactToPrint } from 'react-to-print';
import { useNavigate, useParams } from 'react-router-dom';
import Api from '../../services/Api';
import { useRecoilState } from 'recoil';
import { loadingState } from '../../recoil/atoms/loadingAtom';
import Erreurs from '../../components/Erreurs/Erreurs';
import moment from 'moment';
import Swal from "sweetalert2";
import { toast } from 'react-hot-toast';
function PaymentCMCDetails() {
  const url = process.env.REACT_APP_URL.replace('api/','') + 'storage/';  
  const { agence,decoupage,user,privileges } = useContext(UserContext);
  const [ communes ,setCommunes ] = useState('');
  const [ quartiers,setQuartiers ] = useState('');
  const [ prefectures,setPrefectures ] = useState('')
  const componentRef = useRef();
  const [repeat , setRepeat ] = useState(new Array(2).fill(1));
  const api = new Api();
  const [isLoading ,setIsLoading ] = useRecoilState(loadingState);
  const [ paiement,setPaiement ] = useState('');
  const [ erreurs,setErreurs ] = useState([]);
  const { id } = useParams();
  const { elementsData } = useContext(ElementContext);
  const [ categorie, setCategorie ] = useState('');
  const [isValider,setIsValider ] = useState(false);
  const [ isAnnuler,setIsAnnuler ] = useState(false);
  const [ isEcrasement,setIsEcrasement ] = useState(false);
  const navigate = useNavigate();
  const handlePrint = useReactToPrint({
    // pageStyle: `@media print {
    //     @page {
    //       background:white,
    //       margin: 0;
    //     }
    //   }`,
    content: () => componentRef.current,
  });
  const getPaiement = async ( id ) => {
       setErreurs([]);setIsLoading(true);
       const { status , data ,errors } = await api.apiData("get",`/paiement/cmc/details/${id}`);
       console.log(data);
       setIsLoading(false);
       if( status === 200 ){
          setPaiement(data);
       }
       else setErreurs(errors);
  } 
  useEffect(() => {
   getPaiement(id);
   const _quartiers = decoupage?.quartiers?.filter((q) => q.quartier_id === agence?.quartier_id);
   if(_quartiers?.length > 0 ){
      setQuartiers(_quartiers[0]);
      const _communes = decoupage?.communes?.filter((c) => c.commune_id ===  _quartiers[0].commune_id );
      if( _communes.length > 0 ){
         setCommunes(_communes[0]);
         const _prefectures = decoupage?.prefectures?.filter((p) => p.prefecture_id === _communes[0].prefecture_id);
         if( _prefectures.length > 0 ){
            setPrefectures(_prefectures[0])
         }
      }
   }
   const _categorie = elementsData?.categories?.filter((c) => c.categorie_id == paiement?.type_engin);
   if( _categorie?.length > 0 ){
       setCategorie(_categorie[0].nomCategorie)
   }
  // setIsValider(privileges.filter((p) => p.privilege === "Valider")?.length > 0); 
  const EcrasementPrivileges = privileges.filter((p) => p.privilege === "EcrasementCMC");
  if( EcrasementPrivileges?.length > 0 )
    setIsEcrasement(true)
 

  },[decoupage?.prefectures,privileges,paiement?.paiement_id]);

  const Validation = () => {
      Swal.fire({
         title: 'Validation du Paiement',
         text: 'Valider ou Annuler le paiement',
         icon: 'success',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Valider',
         cancelButtonText: "Annuler"
      }).then((result) => {
         var formdata = new FormData();
         formdata.append("id",id);
         if(result.isConfirmed){
            formdata.append("status","Validé");
            
         }
         else{
            formdata.append("status","Annulé");
         }
         setIsLoading(true);
            api.apiData("post","/paiement/cmc/validation",formdata).then((response) => {
               setIsLoading(false);setErreurs([]);
                if( response.status === 200 ){
                   navigate("/cmc/listpaiement");toast.success("Validation Effectuée avec succès.")
                }
                else{
                  setErreurs(response.messages)
                }
            })
         
      });
   }
  const Ecrasement = () => {
   Swal.fire({
      title: 'Ecrasement du Paiement',
      text: 'Ecraser le paiement',
      icon: 'success',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Valider',
      cancelButtonText: "Annuler"
    }).then((result) => {
      if(result.isConfirmed){
          var formdata = new FormData();
          formdata.append("status","Annulé");
          formdata.append("id",id);
          api.apiData("post","/paiement/cmc/validation",formdata).then((response) => {
            setIsLoading(false);
            setErreurs([]);
            if( response.status === 200 ){
               navigate("/cmc/listpaiement");toast.success("Paiement Annulé avec succès.")
            }
            else{
              setErreurs(response.messages)
            }
          });
      }
    })
  }
  
  return (
    <div className='payment-invoice page'>
          <div className="buttons-group space-between">
             {(paiement.status === "Validé") &&
               <button className="print-btn" onClick={handlePrint}>
                 Imprimer
              </button>}
              {(isEcrasement && paiement?.status === "Validé") && <button className='delete' onClick={Ecrasement}>Ecraser</button>}
            {/* {isValider && paiement.status === "En attente"  && <button className='success' onClick={Validation}>Validation</button>} */}
            {!isEcrasement && <button className='secondary' onClick={() => navigate("/cmc/nouveau")}>
               Nouveau
            </button>}
           
            { (repeat.length === 0 || !categorie )&& <button className='primary' onClick={() => navigate("/cmc/listpaiement")}>Retour</button>}
          </div>
          <Erreurs validation = {erreurs} />
          <div className='invoice-cmc' ref={componentRef}>
           { (repeat.length > 0 && categorie)? repeat.map((item,index) => (
             <div key={index} className='background-img'>  
                <div className="head">
                    <div className='logo'>
                      <img src={ url + agence?.logo }  alt='logo' />
                    </div>
                    <div className='header-1'>
                    <div className='header-1_left'>
                        <h2>{ agence?.nom_agence }</h2>
                        <p>{prefectures?.nom},{ communes.nom } - { quartiers.nom }</p>
                        <p>Téléphone: (+224) 623 41 87 95 / 627 15 98 58 </p> 
                        <p>Email: guineedsd@gmail.com</p>
                    </div>
                    <div className='header-1_right'>
                        <h2>Client</h2>
                         <p>Type : { paiement?.type_client }</p>
                        {paiement.type_client === "Société ou Entreprise" && <p>Designation: { paiement?.nom_societe }</p>}
                        {paiement.type_client === "Personnel" && <h4>{ paiement.prenomClient + " " + paiement?.nomClient } </h4>}
                        <p>Téléphone: { paiement?.telephoneClient }</p>
                        {paiement.emailClient && <p>{ "Email :" + paiement?.emailClient }</p>}
                       
                    </div>
                    </div>
                    <div className='_header'>
                     <div className='header-2'>
                        <h3>Facture Ref: <span>{ paiement.reference }</span></h3>
                        <h3>Date d'émission : <span>{ moment().format("Do/MM/yyyy")}</span></h3>
                     </div>
                     <div className='header-3'>
                        <div className='status'>
                           <div className={`${paiement.status === `Validé`?"valider":paiement.status === `Annulé`?"annuler":"default-status"}`}>{paiement.status}</div> 
                        </div>
                     </div>
                    </div>
                   
                </div>
                <div className='array'>
                   <div className='table'>
                      <table>
                         <thead className="table-header">
                           <tr> 
                             <th>Description</th>
                             <th>Categorie</th>
                             <th>Chassis</th>
                             <th>Prix</th>
                             
                            </tr> 
                         </thead>
                         <tbody>
                            <tr>
                                <td>Certificat de Mise en circulation</td>
                                <td>{ categorie }</td>
                                <td>{paiement?.chassis} </td>
                                <td> 100 000 </td>
                            </tr>
                         </tbody>
                      </table>
                   </div>
                   <div className='total-montant'>
                       <p>Net à payer</p>
                       <p>100 000 FG</p>
                   </div> 
                </div>
                <div className='signature'>
                   <div className='agence'>
                     <p>Signature Banque</p>
                   </div>
                   <div className='client'>
                      <p>Signature Client</p>
                   </div>
                </div>
                <div className="invoice-footer">
                    <p>
                    Pour toute information complémentaire, appelez le 624 93 31 31 ou consultez
                    le portail internet de SIPIM.{" "}
                    <a href="https://support.sipimguinee.com">
                    https://support.sipimguinee.com
                    </a>
                    </p>
                    <p>DSD vous remercie.</p>
                    {index === 0 && <div className='line'></div>}
               </div>
            </div> 
            
              )):
              <>
             
                 <p>Paiement non trouvé</p>
              
              </>
             }
          </div>    
    </div>
  )
}

export default PaymentCMCDetails