import React, { useContext, useEffect, useRef, useState } from 'react'
import logo from "../../assets/images/logo.jpg";
import { UserContext } from '../../services/Context/Context';
import { isObject } from '@mui/x-data-grid/utils/utils';
import CartTableFC from '../CartTableFC/CartTableFC';
import { ToWords } from 'to-words';
import { formatStringNumber } from '../../services/Helpers/fonctions';

const InvoiceDetailsCertificat =   React.forwardRef(({ paiement }, ref) => {
  const componentRef = useRef();
  const [ quartier,setQuartier ] = useState("");
  const [ commune ,setCommune ] = useState('');
  const [ prefecture,setPrefecture ] = useState('')
  const url = process.env.REACT_APP_URL.replace('api/','') + 'storage/';
  const { agence,decoupage,user } = useContext(UserContext);
  const decoup = decoupage;
  const toWords = new ToWords({
    localeCode: 'fr-FR',
    converterOptions: {
      currency: false,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
    }
  });
  useEffect(() => {
      const getQuartier = decoup?.quartiers?.filter((q) => q.quartier_id === agence.quartier_id );
      if( getQuartier?.length > 0 ){
        setQuartier(getQuartier[0]);
        const getCommune = decoup?.communes.filter( (p) => p.commune_id === getQuartier[0].commune_id );
        if( getCommune?.length > 0 ){
            setCommune(getCommune[0]);
            const getPrefecture = decoup?.prefectures.filter((p) => p.prefecture_id === getCommune[0].prefecture_id);
            if( getPrefecture?.length > 0 ){
                setPrefecture(getPrefecture[0]);
            }
        }
      }
  },[decoup]);
  return (
    <div className='invoice' ref={ref}>
      {paiement ?
        <>  
          <div className="head">
            <h1>Reçu de Paiement </h1>
            <div className="logo">{agence.logo?<img src={ url + agence.logo } style={{width:'85px'}}/>:'LOGO'}</div>
          </div>
          <div>
            <div style={{display:'inline'}}>
              <span style={{fontSize:'17px',fontFamily:'sans-serif',fontStyle:'italic'}}>Numéro de Référence : </span> 
              <span style={{fontWeight:'bold',fontFamily:'revert-layer'}}>UEYRHDJEUE893</span>
            </div> 
          </div> 
          <div className="bill-details">
            <div className="from">
                <span className="subtitle">Par </span>
                <strong>DSD Guinée</strong>
                <p>Agence de { agence.nom_agence + " / "+ quartier?.nom } </p>
                <span>Prefecture de <span className='gras'>{prefecture?.nom}</span> </span> 
                <p>Commune de {commune.nom !== "COMMUNE URBAINE" ? <span className='gras'>{commune?.nom}</span>: <span className='gras'>CENTRE</span> }</p>
                <p>Agent : <strong>{ user?.username && user.prenom + " " + user.nom }</strong></p>
            </div>
            <div className="to">
              <span className="subtitle">Client </span>
              <span>Prénom et Nom : <strong> { paiement.prenomDemandeur + " "+ paiement.nomDemandeur } </strong></span>
              <span>Téléphone : <strong> { paiement.telephoneDemandeur }</strong></span>
            </div>  
            </div> 
            <div className='status' style={{ textAlign:'right'}}>
              <span>Status: </span>
                <span className={`${paiement.status === "Validé" ? "valider":paiement.status === "Annulé"?"annuler":"attente" }`}>{ paiement.status }</span>
            </div> 
          <div className="array">
              <CartTableFC paiement={paiement}/>
            </div> 
          <div style={{display:'flex',flexDirection:'column',alignItems:'flex-end'}}>
              <div style={{paddingBottom:'10px'}}>
              <span>Total : <strong>{formatStringNumber("70000")} </strong>fg</span>
              </div>
              <div><strong>{toWords.convert("70000")}</strong> Francs Guinéens</div>
          </div>
          <div className="signature">
            <div>
              <p>Signature de l'agent</p>
            </div>
            <div>
              <p>Signature du client</p>
            </div>
            <div className='qr'>
              <p>QR Code</p>
              <div className='qr-image'>
                <img src={ url + paiement.qrcode } alt='qr-code'/>
              </div>
            </div>
          </div> 
      
        <div className="invoice-footer">
            <p>
              Pour toute information complémentaire, appelez le 622 91 33 33 ou consultez
              le portail internet de SIPIM.{" "}
              <a href="https://support.sipimguinee.com">
              https://support.sipimguinee.com
              </a>
            </p>
            <p>DSD vous remercie.</p>
        </div>
        </>  :
        <p>Réçu de paiement non trouvé</p>
      }  
    </div>
  )
})

export default InvoiceDetailsCertificat;