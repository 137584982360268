import React from 'react'
import { formatStringNumber } from '../../services/Helpers/fonctions'

function CartTableFC({paiement}) {
  // console.log(paiement)
  return (
    <div className='cart-table'>
       {paiement &&
        <table style={{ width:'100%'}}>
            <thead className="table-header">
                <tr>
                  <th>Document</th>
                  <th>Direction</th>
                  <th style={{ textAlign:'end'}}>Prix (en fg)</th>
                
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{paiement.typedocument}</td>
                    <td>{ paiement.departement }</td>
                    <td style={{ textAlign:'end'}}> {formatStringNumber("50000") } </td>
                </tr>
                <tr>
                  <td>Commission</td>
                  <td colSpan={2} style={{ textAlign:'end',marginRight:'40px'}}>{formatStringNumber("20000") }</td>
                </tr>
            </tbody>
        </table> 
       }  
    </div>
  )
}

export default CartTableFC