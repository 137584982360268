import React, { useEffect, useState } from 'react'
import DocumentTitle from '../../components/DocumentTitle/DocumentTitle'
import Erreurs from '../../components/Erreurs/Erreurs';
import { formatStringNumber } from '../../services/Helpers/fonctions';
import { DataGridPremium, GridToolbar, frFR } from "@mui/x-data-grid-premium";
import Box from "@mui/material/Box";
import { useRecoilState } from 'recoil';
import { loadingState } from '../../recoil/atoms/loadingAtom';
import Api from '../../services/Api';
import moment from 'moment/moment';
import { ToWords } from 'to-words';

function StatistiqueParCategorie() {
  const [erreurs,setErreurs ] = useState([]); 
  const[sum,setSum ] = useState(0);
  const [isLoading,setIsLoading] = useRecoilState(loadingState);
  const[ paiementData,setPaiementData ] = useState([]);
  const api = new Api();
  const toWords = new ToWords({
    localeCode: 'fr-FR',
    converterOptions: {
      currency: false,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
    }
  });
  const columns = [
    {
      field: "ordre",
      headerName: "N°",
      minWidth: 80,
    },
    {
      field: "categorie",
      headerName: "Categorie",
      minWidth: 180
    },
    {
        field: "nombre",
        headerName: "Nombre",
        minWidth: 210 
    },
    {
        field: "montant",
        headerName: "Montant Total",
        minWidth: 250 ,
        flex:1
    }
   ];
   const getData = async () => {
    setIsLoading(true);setErreurs([]);
      const { status,data,errors }  = await api.apiData("get",`/paiement/cmc/statistiques/parcategorie`);
      if( status === 200 ){
        let _sum = 0;
        const arr = data.map((item,index) => {
          _sum = _sum + item.Total ;
          return{
            id:Math.random() + index,
            ordre:  index + 1,
            categorie: item.categorie,
            nombre: item.Total,
            montant: formatStringNumber(item.Total * 100000)
          }
        });
        // console.log(data)
        setSum(_sum)
        setPaiementData(arr);
      }
     setIsLoading(false);
   }
   useEffect(() => {
      getData();
   },[])
  return (
    <div className='payment-list page'>
     <DocumentTitle title="Liste de paiements des CMC Categorie/Jour" />
     <Erreurs validation={erreurs} /> 
       <div className='header'>
           <h3> Statistiques Paiement par Categorie et Jour</h3>
            <p>
              paiements effectués |  
              <span>
                <strong> Montant Global : </strong>
                {toWords.convert(sum * 100000) + " Francs Guinéens"} (
                <strong>{formatStringNumber(sum * 100000)+ " fg"}</strong>)
              </span>
            </p>
       </div>  
       <div className="array">
        <Box sx={{ height: "100vh", width: "100%" }}>
           <DataGridPremium
              sx={{ borderRadius: 0 }}
              density="compact"
              components={{ Toolbar: GridToolbar }}
              // rows={paiementData}
              columns={columns}
              autoPageSize
              pagination
              disableSelectionOnClick
              //rowCount={pageState.total}
              rows={paiementData}
              // page={pageState.page - 1 }
              localeText={ frFR.components.MuiDataGrid.defaultProps.localeText }
              //paginationMode='server'
              slots={{ toolbar: GridToolbar }}
              //filterMode="server"
             // onFilterModelChange={(newFilterModel) => {
               // setFilterModel(newFilterModel);
              
              //}}
             // onPageSizeChange={(newpageSize, details ) => {
                // Maybe save into state
               // setPageState((prev) => ({...prev,pageSize: newpageSize}))
              //}}
              // onPageChange={(newPage) => {
              //   setPageState(prev => ({ ...prev, page: newPage + 1 }))
              // }}
              //apiRef={apiRef}
            
              // componentsProps={{
              //   toolbar: {
              //     csvOptions: { disableToolbarButton: false },
              //     printOptions: { disableToolbarButton: false },
              //     // showQuickFilter: true,
              //     quickFilterProps: { debounceMs: 250 },
              //   },
              // }}
              //componentsProps={{ toolbar: { filterModel,isLoading,setIsLoading } }}

              // experimentalFeatures={{ newEditingApi: true }}
              />
        </Box>
       </div> 
    </div>
  )
}

export default StatistiqueParCategorie