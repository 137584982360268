import React, { useState } from 'react'
import DocumentTitle from '../../components/DocumentTitle/DocumentTitle'
import Stepper from '../../components/Stepper/Stepper';
import { paymentSteps } from "../../utils/texts";
import StepOne from '../../components/OrganisationSteps/StepOne';
import StepTwo from '../../components/OrganisationSteps/StepTwo';
import StepThree from '../../components/OrganisationSteps/StepThree';
function Organisation() {
    const [activeStep, setActiveStep] = useState(1);
    const [ paiementData,setPaiementData ] = useState({
        typeOrganisation_id: '',
        organisation_id:'',
        chassis:'',
        typeOperation: '',
        categorie_id: '',
        telephone:'',
        prix:'',

    })
    const nextStep = () => {
        setActiveStep(activeStep + 1)
    }
    const prevStep = () => {
        setActiveStep(activeStep - 1)
      }
    return (
        <div className='payment page'>
            <DocumentTitle title="Nouveau paiement" />
            <div className="header">
                <h3>Paiement d'immatriculation des organisations</h3>
                <p>Suivez les trois étapes suivantes pour créer un nouveau paiement des organisations.</p>
            </div>
            <div className="payment-zone">
                <div className="payment-steps">
                <Stepper steps={paymentSteps} activeStep={activeStep} />
                </div>
                <div className="payment-form">
                    {activeStep === 1 && 
                        <StepOne nextStep={nextStep} prevStep={prevStep}
                        paiementData = {paiementData}
                        setPaiementData = { setPaiementData }
                        />
                    }
                   {activeStep === 2 && <StepTwo nextStep={nextStep} prevStep={prevStep}  
                      paiementData = {paiementData}
                      setPaiementData = {setPaiementData}
                   />}
                   {activeStep === 3 && <StepThree nextStep={nextStep} prevStep={prevStep} 
                   paiementData = {paiementData}
                   />}
                </div>  
            </div>   
       </div>
  )
}

export default Organisation