import React, { useContext, useEffect, useRef, useState } from 'react'
import InvoiceDetailsCertificat from '../../components/InvoiceDetailsCertificat/InvoiceDetailsCertificat'
import { UserContext } from '../../services/Context/Context';
import { useNavigate, useParams } from 'react-router-dom';
import Api from '../../services/Api';
import { useReactToPrint } from 'react-to-print';
import "./PaymentCertificatDetails.scss";
import Swal from "sweetalert2";
import { toast } from 'react-hot-toast';
import Erreurs from '../../components/Erreurs/Erreurs';
function PaymentCertificatDetails() {
  const componentRef = useRef();
  const [validerPaiement,setValiderPaiement] = useState();
  const [annulerPaiement,setAnnulerPaiement] = useState();
  const [ nouveauPrivilege,setNouveauPrivilege ] = useState();
  const [ annulerModal,setAnnulerModal ] = useState(false);
  const [ paiement,setPaiement ] = useState({});
  const { privileges } = useContext(UserContext);
  const [ isLoading,setIsLoading ] = useState(false);
  const [ erreurs,setErreurs ] = useState([]);
  const navigate = useNavigate();
  // console.log(privileges)
  const api = new Api();
  const { id } = useParams();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const getCurrentPaiement = async (id) => {
      const response =  await api.apiData("get",`/paiement/certificat/getpaiement/${id}`);
      if( response.status === 200 ){
        setPaiement(response?.data);
       
      }
      
  }
  useEffect(() => {
    setNouveauPrivilege(); 
    setAnnulerPaiement();
    setValiderPaiement();
    getCurrentPaiement(id);
      if(privileges){
          privileges.forEach((privilege) => {
          if(privilege.privilege === "Nouveau Paiement"){
              setNouveauPrivilege(privilege.privilege);
          }else if(privilege.privilege === "Annuler Paiement"){
              setAnnulerPaiement(privilege.privilege);
          }else if(privilege.privilege === "Valider"){
              setValiderPaiement(privilege.privilege);
          }
          })
      }
    },[privileges])
  const Validation = () => {
    Swal.fire({
      title: 'Validation du Paiement',
      text: 'Valider ou Annuler le paiement',
      icon: 'success',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Valider',
      cancelButtonText: "Annuler"
   }).then((result) => {
      var formdata = new FormData();
      formdata.append("id",id);
      if(result.isConfirmed){
         formdata.append("status","Validé");
         
      }
      else{
         formdata.append("status","Annulé");
      }
      setIsLoading(true);
         api.apiData("post","/paiement/certificat/validation",formdata).then((response) => {
            setIsLoading(false);setErreurs([]);
             if( response.status === 200 ){
                navigate("/certificatperte/list");
                toast.success("Validation Effectuée avec succès.");

             }
             else{
               setErreurs(response.messages)
             }
         })
      
   });
  }

  return (
    <div className='payment-invoice page'>
      <div className="buttons-group space-between">
       {/* <RejectionModal
          isOpen={annulerModal}
          setIsOpen={s
            etAnnulerModal} id={id}
        /> */}
           <Erreurs  validation={erreurs} />
            <div className="left">
            {paiement &&
            <button className="print-btn" onClick={handlePrint}>
              Imprimer
            </button>}
            {(validerPaiement === "Valider" && paiement?.status === "En attente") &&
               <button className='success' onClick={Validation}>Valider</button>
            }
        
    
        
          {/* {(validerPaiement === "Valider" && paiement.status === "Non Validé") &&
          <button className="primary new-payment-btn">
            Valider
          </button>} */}
         
        </div>
        {/* {(annulerPaiement === "Annuler Paiement" && paiement.status === "Non Validé") &&
          <div className="right">
            <button className="delete cancel-btn" onClick={() => setAnnulerModal(!annulerModal)}>Annuler paiement</button>
          </div>
         } */}
       </div>
       {paiement && <InvoiceDetailsCertificat paiement={paiement} ref={componentRef}/> }
    </div>
  )
}

export default PaymentCertificatDetails