import React, { useEffect } from 'react'
import SuccessCheck from '../SuccessCheck/SuccessCheck'
import { useNavigate } from 'react-router-dom'

function StepThree({ paiement }) {
  const navigate = useNavigate();
  useEffect(() => {
     setTimeout(() => {
          navigate(`/certificatperte/invoicedetails/${paiement.paiement_id}`)
     },[5000])
  },[])  
  return (
    <div className="step-component step-three">
        <h4>Votre paiement a été effectué avec succès.</h4>
        <div className="success-animation">
        <SuccessCheck />
        </div>
        <p>Vous allez être redirigé vers votre reçu dans 5 secondes.</p>
    </div>
  )
}

export default StepThree