import React, { useState } from 'react';
// import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Button } from "@mui/material";
import { GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { isEmpty, objecttoFormData } from '../../services/Helpers/fonctions';
import Api from '../../services/Api';
import * as XLSX from 'xlsx';
import moment from 'moment/moment';
function ToolbarCMC({ filterModel,filterData,isLoading,setIsLoading }) {
   
    const [disabled, setDisabled] = useState(true);
    const [ datatoExport,setDatatoExport ] = useState([]);
    const api = new Api();
    // function getRandomInt(max) {
    //   return Math.floor(Math.random() * max);
    // }
    const onExportClick = async () => {
      setIsLoading(true);
       var formdata ;var mode = 'soft';var url = '';
       var formdata;
       formdata = objecttoFormData(filterModel);
     
       if( filterData?.startDate || filterData?.endDate ){
          formdata.append('filterDate',JSON.stringify(filterData));
        //  formdata.append('params',JSON.stringify(filterModel?.items));
      }
      //  if( isEmpty(filterData) ){
      //     formdata = objecttoFormData(filterModel);
      //  }
      //   else {
      //     formdata = objecttoFormData(filterData);
      //     mode="rigide"
      //   }
      
        const { status,data,errors } = await api.apiData("post",`/paiement/cmc/liste?options='export'&mode=${mode}`,formdata);
        // console.log(data);
        if( data.length > 0 ){
         const workbook = XLSX.utils.book_new();
         const _data = data.map((dt,index) => {
          return {
            ordre: index + 1,
            reference: dt.reference,
            categorie: dt.nomCategorie,
            chassis: dt.chassis,
            montant: dt.montant,
            nomClient: dt.nomClient,
            prenomClient: dt.prenomClient,
            telephoneClient: dt.telephoneClient,
            emailClient: dt.emailClient ? dt.emailClient:'Non fournie' ,
            nomAgent: dt.nomAgent,
            prenomAgent: dt.prenomAgent,
            agence:dt.nom_agence,
            prefecture:dt.prefecture,
            commune: dt.commune,
            quartier:dt.quartier,
            status: dt.status,
            date: moment(dt.created_at).format('Do MMMM YYYY, H:mm:ss')

          }
         })
         const worksheet = XLSX.utils.json_to_sheet(_data);
          // Add worksheet to workbook
         XLSX.utils.book_append_sheet(workbook, worksheet, 'Stattistique');
         XLSX.writeFile(workbook, 'export_' + moment().format('dddd-Do-MMMM-YYYY')  + '.xlsx');
       }
      setIsLoading(false);  
    }
    
    return (
        <GridToolbarContainer>
          <GridToolbarFilterButton />  
          <Button
            // startIcon={<SaveAltIcon />}
            onClick={onExportClick}
            //disabled={disabled}
          >
           Exporter
          </Button>
          {/* <Button onClick={() => setDisabled((state) => !state)}>
            {disabled ? "Enable" : "Disable"} export button
          </Button> */}
        
        </GridToolbarContainer>
      );
    };

export default ToolbarCMC