import React, { useEffect, useState } from 'react'
import "./PaiementListeCMCDouane.scss";
import DocumentTitle from '../../components/DocumentTitle/DocumentTitle';
import moment from 'moment';
import { useRecoilState } from 'recoil';
import { loadingState } from '../../recoil/atoms/loadingAtom';
import Api from '../../services/Api';
import Erreurs from '../../components/Erreurs/Erreurs';
import { DataGridPremium, GridToolbar, frFR } from "@mui/x-data-grid-premium";
import Box from "@mui/material/Box";

function PaiementListeCMCDouane() {
  const [cmcdata,setCmcdata ] = useState([]); 
  const [ isLoading,setIsLoading ] = useRecoilState(loadingState);
  const [erreurs,setErreurs ] = useState();
  const[ pageState,setPageState ] = useState({
      data:[],
      total: 0,
      page: 1,
      pageSize: 10 
  })
  const api = new Api();
  const columns = [
        {
            field: "ordre",
            headerName: "N°",
            minWidth: 80,
        },
        {
            field:"chassis",
            headerName:"Chassis",
            minWidth:180
        },
        {
            field:"identifiant",
            headerName:"Identifiant",
            minWidth:140
        },
        {
            field:'type',
            headerName: "Type d'engin",
            minWidth:180
        },
        {
            field:'marque',
            headerName: "Marque",
            minWidth:180
        },
        {
            field:'date',
            headerName: "Date",
            minWidth:150,
            flex:1
        }
   ];
   
   const getData = (data) => {
    setCmcdata([]);
   
    if( data?.data?.length > 0 ){
       return data.data.map((item,index) => {

            return {
                id: item.id,
                ordre: data.from + index,
                identifiant: item.identifiant,
                type: item.type_engin,
                marque:item.marque,
                chassis: item.chassis,
                date: moment(item.created_at).format("Do MMMM YYYY")  
            }
        });
    }
    return [];
  }
  const getInitialData = async () => {
    setErreurs([]);setIsLoading(true);
      const { status,messages, data } = await api.apiData("get",`/dsd/douane/paiementscmc?pageSize=${pageState.pageSize}&currentPage=${pageState.page}`);
     
      //console.log(data)
  
    setIsLoading(false);
    if( status === 200 ){
       if( pageState.page <= data.last_page)
         setPageState( old => ({...old,data:getData(data),total:data.total }))
    }
    else{
        setErreurs(messages);
    }
   // setIsLoading(false)
   // console.log(data)
  }
 
  useEffect(() => {
    getInitialData()
  },[pageState.page,pageState.pageSize])
  return (
    <div className='page douane'>
         <DocumentTitle title="Liste des cmc payés à la douane" />
        <div className='header'>
           <h2>Liste des véhicules dédouanés</h2>
        </div>
        <Erreurs validation={erreurs} />
         <div className="array">
          <Box sx={{ height: "100vh", width: "100%" }}>
            <DataGridPremium
              sx={{ borderRadius: 0 }}
              density="compact"
              components={{ Toolbar: GridToolbar ,}}
              rows={pageState.data}
              columns={columns}
              rowCount={pageState.total}
              autoPageSize
              pagination
              page={pageState.page - 1 }
              disableSelectionOnClick
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              slots={{ toolbar: GridToolbar }}
              paginationMode='server'
           
              onPageSizeChange={(newpageSize, details ) => {
                // Maybe save into state
                setPageState((prev) => ({...prev,pageSize:newpageSize}))
              }}
              onPageChange={(newPage) => {
                setPageState(prev => ({ ...prev, page: newPage + 1 }))
              }}
              />
          </Box>
        </div> 
    </div>
  )
}

export default PaiementListeCMCDouane