export const TDocument =  [{
    id: 1,
    departement: 'Direction nationale de la Securité Routière',
    types:["Permis de Conduire","Carte Grise","Autorisation de transport","Certificat de non gage"],
    montant: 50000
  },
  {id:2,
    departement: "Direction nationale de la police des aires et des frontières",
    types:["Carte d'identité nationale","Passport"],
    montant: 50000
  }
]