import { useCallback, useContext, useEffect, useState } from "react";
import { DataGridPremium, GridToolbar, frFR } from "@mui/x-data-grid-premium";
import DocumentTitle from "../../components/DocumentTitle/DocumentTitle";
import { ToWords } from 'to-words';
import Box from "@mui/material/Box";
import Erreurs from "../../components/Erreurs/Erreurs";
import Api from "../../services/Api";
import { UserContext } from "../../services/Context/Context";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { StringToUpperCase, formatStringNumber, objecttoFormData } from "../../services/Helpers/fonctions";
import { useRecoilState } from "recoil";
import { loadingState } from "../../recoil/atoms/loadingAtom";
function PaiementCertificatList() {

  
  const [sortModel, setSortModel] = useState([]);
  const { user } = useContext(UserContext);
  const[ pageState,setPageState ] = useState({
    data:[],
    total: 0,
    page: 1,
    pageSize: 10 
  })
  const api = new Api();
  const [ montantGlobal,setMontantGlobale ] = useState(0);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const toWords = new ToWords({
    localeCode: 'fr-FR',
    converterOptions: {
      currency: false,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
    }
  });
  const [ erreurs,setErreurs ] = useState([]);
  const navigate = useNavigate();
  const [ isLoading,setIsLoading ] = useRecoilState(loadingState);
  const [ chercheField,setChercheField ] = useState({
    reference: '',
    startDate: '',
    endDate:''
  });
  const columns = [
    {
      field: "ordre",
      headerName: "N°",
      minWidth: 80,
    },
    {
      field:"departement",
      headerName:"Departement",
      minWidth:150  
    },
    {
        field:"typedocument",
        headerName:"Type Document",
        minWidth:120
    },
    {
      field:"reference",
      headerName:"Réference",
      minWidth:130
  },
    {
        field:"prenomnomclient",
        headerName:"Prenom & Nom client",
        minWidth:220
    },
    {
        field:"telephoneclient",
        headerName:"Téléphone client",
        minWidth:180
    },
    {
        field:"prenometutilisateur",
        headerName:"Prenom et Nom utilisateur",
        minWidth:220
    },
    {
        field:"status",
        headerName:"Status",
        minWidth:90
    },
    {
        field:"datecreation",
        headerName:"Date de Creation",
        minWidth:120
    },
    {
        field: "options",
        headerName: "Options",
        sortable: false,
        flex: 1,
        hide:false,
        minWidth: 150,
        renderCell: (params) => {
          const id = params.id;
          return (
            <div className="options">
               <button onClick={() => navigate(`/certificatperte/invoicedetails/${id}`)} >Voir facture</button>
            </div>
          );
        },
     }
 ];
 const getPaiements = async () => {
    setIsLoading(true);
    setPageState((prev) => ({...prev,data:[]}));
    const { status,data,errors } = await api.apiData("get",`/paiement/certificat/getpaiements?pageSize=${pageState.pageSize}&currentPage=${pageState.page}`);
 
    const arr = [];
    if( status === 200 ){
        if( data.data.length > 0 ){
         const totalMontant = 50000 * data.total;
         const arr =  data.data.map((item,index) => {
            const username = user.id == item.user_id ? "Vous même" : item.prenom + " "+ item.nom;
             return {
                id: item.paiement_id,
                ordre: data.from + index ,
                departement: item.departement,
                typedocument: item.typedocument,
                prenomnomclient: item.prenomDemandeur + " "+ item.nomDemandeur,
                telephoneclient: item.telephoneDemandeur,
                prenometutilisateur: username,
                reference: item.reference,
                status: item.status,
                datecreation:moment(item.created_at).format('Do MMMM  YYYY H:m')
             }
         });
         
         setMontantGlobale(totalMontant)
         setPageState((prev) => ({...prev,data: arr,total:data.total}));
        }
    } 
    setIsLoading(false);
 
 }  
 const handleInput = (e) => {
   if(e.target.name !== 'reference' )
     setChercheField({...chercheField,[ e.target.name ]: e.target.value });
   else setChercheField({...chercheField,[ e.target.name ]: e.target.value.toUpperCase() }); 
 }
 const submitSearch = async (e) => {
    e.preventDefault();
    var formdata = objecttoFormData(chercheField);
    setIsLoading(true);
      const { status, data,errors } = await api.apiData("post","/paiement/certificat/search",formdata);
    setIsLoading(false);
    if( status === 200 ){
       if( data.length > 0 ){
        pageState((prev) => ({...prev,data:
          data.map((item,index) => {
            const username = user.id == item.user_id ? "Vous même" : item.prenom + " "+ item.nom;
             return {
                id: item.paiement_id,
                ordre: index + 1,
                departement: item.departement,
                typedocument: item.typedocument,
                prenomnomclient: item.prenomDemandeur + " "+ item.nomDemandeur,
                telephoneclient: item.telephoneDemandeur,
                prenometutilisateur: username,
                reference: item.reference,
                status: item.status,
                datecreation:moment(item.created_at).format('Do MMMM  YYYY H:m')
             }
         })
        }))
        
       }
    }

 }
 const filterData = async (filterOption) => {
  //veriable pour verifier si le filtre est active
   if(filterOption.items.length > 0 ){
     var formdata = objecttoFormData(filterOption);
     const response = await api.apiData("post",`/paiement/certificat/filter?pageSize=${pageState.pageSize}&page=${pageState.page}`,formdata);
     

    if( response.status === 200 ){
      if(response.data.data.length > 0){
        const totalMontant = 50000 * response.data.total;
        const arr =  response.data.data.map((item,index) => {
          const username = user.id == item.user_id ? "Vous même" : item.prenom + " "+ item.nom;
          return {
              id: item.paiement_id,
              ordre:  response.data.from + index ,
              departement: item.departement,
              typedocument: item.typedocument,
              prenomnomclient: item.prenomDemandeur + " "+ item.nomDemandeur,
              telephoneclient: item.telephoneDemandeur,
              prenometutilisateur: username,
              reference: item.reference,
              status: item.status,
              datecreation:moment(item.created_at).format('Do MMMM  YYYY H:m')
          }
        });
        setMontantGlobale(totalMontant)
        setPageState((prev) => ({...prev,data: arr,total:response.data.total}));
     }
    }
   }
   else {
    getPaiements();
   }
  
 }
 useEffect(() => {
   if( filterModel?.items?.length === 0 ) 
      getPaiements();
    else{
      filterData(filterModel);
    }  
 },[user.id,pageState.page,pageState.pageSize,filterModel]);
 //console.log(pageState)
 
 return (
    <div className='payment-list page'>
       <DocumentTitle title="Liste des paiements des certificats" />
       <div className='header'>
         <h3>Liste de paiements des certificats</h3>
            <p>
             paiements validés | {" "}
            <span>
                <strong>Montant Global : </strong>{" "}
                {typeof montantGlobal === 'number' && toWords.convert(montantGlobal) + " Francs Guinéens"} (
                <strong>{formatStringNumber(montantGlobal)+ " fg"}</strong>)
            </span>
            </p>
        </div>  
         <div className="filters">
          <form onSubmit={submitSearch}>

          <h4>Filtres</h4>
          <div className="input-group">
            <label>
              Numero de référence
              <input type="text"
                   name="reference" id="numRef" 
                   placeholder="Numéro de référence"
                   value={ StringToUpperCase(chercheField?.reference) } 
                    onChange={handleInput}
                   />
            </label>
           
            <label>
              Date début
              <input type="date" name="startDate" 
                 id="startDate" 
                 placeholder="Date de début"
                 onChange={handleInput}
                 />
            </label>
            <label>
              Date fin
              <input type="date" name="endDate"
                 id="endDate" 
                 placeholder="Date de fin"
                 onChange={handleInput}
                 />
            </label>
          </div>
          <Erreurs validation = {erreurs} />
          <button type="submit">Rechercher</button>
        </form>
        </div>  
        {/* {pageState.data.length > 0 ? ( */}
         <div className="array">
          <Box sx={{ height: "100vh", width: "100%" }}>
            <DataGridPremium
              sx={{ borderRadius: 0 }}
              density="compact"
              components={{ Toolbar: GridToolbar}}
              // rows={paiementData}
              columns={columns}
              autoPageSize
              pagination
              disableSelectionOnClick
              rowCount={pageState.total}
              rows={pageState.data}
              page={pageState.page - 1 }
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              paginationMode='server'
              slots={{ toolbar: GridToolbar }}
              onPageSizeChange={(newpageSize, details ) => {
                // Maybe save into state
                setPageState((prev) => ({...prev,pageSize:newpageSize}))
              }}
              onPageChange={(newPage) => {
                setPageState(prev => ({ ...prev, page: newPage + 1 }));
              }}
              filterMode="server"
              onSortModelChange={setSortModel}
              onFilterModelChange={(newFilterModel) => {
                setFilterModel(newFilterModel);
              
              }}
              />
          </Box>
        </div>
        {/* ) : <p>Aucun paiement dans le tableau.</p>} */}
    </div>
  )
}

export default PaiementCertificatList