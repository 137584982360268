import React, { useEffect, useState } from 'react'
import DocumentTitle from '../../components/DocumentTitle/DocumentTitle'
import Erreurs from '../../components/Erreurs/Erreurs'
import Box from "@mui/material/Box";
import { DataGridPremium, GridToolbar, frFR } from "@mui/x-data-grid-premium";
import { Link } from 'react-router-dom';
import Api from '../../services/Api';
import { useRecoilState } from 'recoil';
import { loadingState } from '../../recoil/atoms/loadingAtom';
import moment from 'moment';
import { formatStringNumber } from '../../services/Helpers/fonctions';
function PaymentListOrganisation() {
   const [erreurs,setErreurs ] = useState([]);   
   const [ paiementData,setPaiementData ] = useState([]);
   const [isLoading ,setIsLoading ] = useRecoilState(loadingState);
   const [ filterData,setFilterData ] = useState([]);
   const [ filter,setFilter ] = useState({
    reference:'',
    chassis:'',
    date_debut: '',
    date_fin: '',
   })
   const api = new Api();

   const columns = [
    {
      field: "reference",
      headerName: "Numero de reference",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "chassis",
      headerName: "Numero de chassis",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "typeOperation",
      headerName: "Mode d'exploitation",
      flex: 1,
      minWidth: 150,
    },

    {
      field: "prix",
      headerName: "Prix (fg)",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return formatStringNumber(params.row.prix)
      }
    },
    
    {
      field: "TypeOrganisation",
      headerName: "T.Organisation",
      flex: 1,
      minWidth: 150,
    },
    {
        field: "organisation",
        headerName: "Organisation",
        flex: 1,
        minWidth: 150,
      },
    {
      field: "region",
      headerName: "Region",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "nom_agence",
      headerName: "Banque",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "created_at",
      headerName: "Date",
      flex: 1,
      minWidth: 150,
      renderCell:(params) => {
         return moment(params.row.created_at).format("Do MMMM YYYY")
      }
    },
      {
        field: "options",
        headerName: "Options",
        sortable: false,
       
        flex: 1,
        minWidth: 150,
        renderCell: (params) => {
          const id = params.row.reference;
          return (
            <div className="options">
              <Link to={`/payment/invoice/organisation/${id}`}>
                <button>Voir facture</button>
              </Link>
            </div>
          );
        },
     }
  ];
  const getAllPaiement = async () => {
    setErreurs([]);setIsLoading(true);
    const { status ,messages , paiements } = await api.apiData("get","/paiement_organisation/getpaiements");
    setIsLoading(false);
    if(status === 200 ){
      setPaiementData(paiements);
    }
    else {
      setErreurs(messages)
    }
  }
  useEffect(() => {
     getAllPaiement();
  },[]);
  const handleInput = (e) => {
    setFilter({...filter,[e.target.name]: e.target.value.toUpperCase() });
  }
  const search = (e) => {
    e.preventDefault();setFilterData([]);
    if(filter.reference !== '')
     setFilterData(paiementData.filter((p) => p.reference.indexOf(filter.reference) > - 1));
   else if( filter.reference !== '' && filter.chassis !== "" && filter.date_debut === '' && filter.debut_fin === '' )  
     setFilterData(paiementData.filter((p) => p.reference.indexOf(filter.chassis) > - 1 && p.reference.indexOf(filter.chassis)));
   else if( filter.reference !== '' && filter.chassis !== "" && filter.date_debut !== '' && filter.debut_fin === '' )  
     setFilterData(paiementData.filter((p) => p.reference.indexOf(filter.chassis) > - 1 
      && p.reference.indexOf(filter.chassis) && moment(p.created_at).isAfter(filter.date_debut)));   
   else if( filter.reference !== '' && filter.chassis !== "" && filter.date_debut !== '' && filter.debut_fin !== '' )  
     setFilterData(paiementData.filter((p) => p.reference.indexOf(filter.chassis) > - 1 
      && p.reference.indexOf(filter.chassis) && moment(p.created_at).isAfter(filter.date_debut) 
      && moment(p.created_at).isBefore(filter.date_fin)));  
   else if( filter.reference === '' && filter.chassis === "" && filter.date_debut !== '' && filter.debut_fin === '' ) 
     setFilterData(paiementData.filter((p) => moment(p.created_at).isAfter(filter.date_debut)));   
   else if( filter.reference === '' && filter.chassis === "" && filter.date_debut === '' && filter.debut_fin !== '' ) 
     setFilterData(paiementData.filter((p) => moment(p.created_at).isSameOrBefore(filter.debut_fin))); 
   else if( filter.reference === '' && filter.chassis === "" && filter.date_debut !== '' && filter.debut_fin !== '' ) 
     setFilterData(paiementData.filter((p) => moment(p.created_at).isAfter(filter.date_debut) && moment(p.created_at).isSameOrBefore(filter.date_fin)));   
   else if( filter.chassis !== ""  && filter.reference === "" && filter.date_debut === "" && filter.date_fin === "") 
     setFilterData(paiementData.filter((p) => p.chassis.indexOf(filter.chassis) > - 1));
  }
  return (
    <div className='payment-list page'>
       <DocumentTitle title="Liste des paiements" />
       <div className="header">
        <h3>Liste des paiements</h3>
        <p>
        
          <span>
            <strong>Montant Global : </strong>{" "}
            {/* {toWords.convert(globalMontant) + " Francs Guinéens"} (
            <strong>{formatStringNumber(globalMontant) + " fg"}</strong>) */}
          </span>
        </p>
       </div>
       <div className="filters">
        <form onSubmit={search}>

          <h4>Filtres</h4>
          <div className="input-group">
            <label>
              Numero de référence
              <input type="text"
                   name="reference" id="reference" 
                   placeholder="Numéro de référence"
                   value={filter.reference}
                   onChange={(e) => handleInput(e)}
                 />
            </label>
            <label>
              Numero de chassis
              <input type="text" 
                  name="chassis" id="chassis" 
                  placeholder="Numéro de chassis"
                  value={filter.chassis}
                  onChange={(e) => handleInput(e)}
                  />
            </label>
            <label>
              Date début
              <input type="date" name="date_debut" 
                 id="date_debut" 
                 placeholder="Date de début"
                 value={filter.date_debut}
                 onChange={(e) => handleInput(e)}
                 />
            </label>
            <label>
              Date fin
              <input type="date" name="date_fin"
                 id="date_fin" 
                 placeholder="Date de fin"
                 onChange={(e) => handleInput(e)}
                 />
            </label>
          </div>
          <Erreurs validation = {erreurs} />
          <button type="submit">Rechercher</button>
        </form>
      </div>
      {/* {paiementData.length > 0 ? ( */}
        <div className="array">
          <Box sx={{ height: 579, width: "100%" }}>
            <DataGridPremium
              sx={{ borderRadius: 0 }}
              density="compact"
              components={{ Toolbar: GridToolbar}}
              rows={filterData.length === 0 ? paiementData:filterData}
              columns={columns}
              autoPageSize
              pagination
              disableSelectionOnClick
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              />
          </Box>
        </div>
        {/* ) : <p>Aucun paiement dans le tableau.</p>} */}
    </div>
  )
}

export default PaymentListOrganisation