import React, { useContext, useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { ElementContext } from '../../services/Context/Context';
function StepOne({nextStep,paiementData,setPaiementData }) {
    const { elementsData } = useContext(ElementContext);
    const [ organisations,setOrganisations ] = useState([]);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm({defaultValues: {paiementData,},});
  const handleInput = (e) => {
    if( e.target.name === "typeOrganisation_id" ){
       
        setOrganisations(elementsData.Organisations.filter((p) => p.type_organisation_id == e.target.value));
        setPaiementData({...paiementData,[e.target.name]:e.target.value,organisation:''});
    }
    if(e.target.name === "telephone" ){
      const re = /^[0-9\b]+$/;
      if( (re.test(e.target.value) || e.target.value === '') && e.target.value.length <= 9 ){
        setPaiementData({...paiementData,[e.target.name]:e.target.value});
      }
      return;
    }
    else if( e.target.name === "chassis" ){
      if(e.target.value.length <= 17)
        setPaiementData({...paiementData,[e.target.name]:e.target.value});
    }
    else {
    setPaiementData({...paiementData,[e.target.name]:e.target.value});}
  }  
  useEffect(() => {
    
    return () => {
        setOrganisations(elementsData?.Organisations.filter((p) => p.type_organisation_id == paiementData?.typeOrganisation_id));
    }

  },[elementsData?.typeOrganisations]);
  //  console.log(elementsData)
  return (
    <div className="step-component">
       <h4>Informations</h4>
         {paiementData &&
          <form onSubmit={handleSubmit(nextStep)}>
          <div className="input-group">
            <div>
                <label>Type d'organisation
                    <select
                      name="typeOrganisation_id"
                      id="typeOrganisation_id"
                      value={paiementData.typeOrganisation_id}
                      {...register("typeOrganisation_id", {
                        onChange: (e) => {
                          handleInput(e);
                        },
                        validate: (value) => value !== "",
                      })}
                  
                    >
                        <option value="">-</option> 
                        {
                          elementsData?.typeOrganisations.map((typeOrganisation) => {
                       
                            return(
                              <option key={typeOrganisation.typeOrganisation_id} value={typeOrganisation.typeOrganisation_id}>{ typeOrganisation.nom }</ option>
                            )})
                        }
                     
                    </select>
                    {errors.typeOrganisation_id && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                </label>
            </div>
            <div>
                <label>Organisation
                   <select
                   name="organisation_id"
                   id="organisation_id"
                   value={paiementData.organisation_id}
                   {...register("organisation_id", {
                     onChange: (e) => {
                       handleInput(e);
                     },
                     validate: (value) => value != "",
                   })}
                
                   >
                      <option value="">-</option>
                      {elementsData?.Organisations.filter((p) => p.type_organisation_id == paiementData?.typeOrganisation_id).map((organisation) => {
                        return(
                          <option key={organisation.organisation_id} value={organisation.organisation_id}>{organisation.nom}</option>
                        )
                      })
                      }
                   </select>
                   {errors.organisation_id && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                </label>
            </div>
          </div>  
          <div className="input-group">
            <div>
                <label>Numéro de Chassis
                   <input type='text' autoComplete='off' 
                      value={paiementData.chassis !== '' ? paiementData.chassis.toUpperCase():paiementData.chassis}
                      placeholder="Numéro de chassis"
                      id="chassis"
                      {...register("chassis", {
                        onChange: (e) => {
                          handleInput(e);
                        },
                        required: true,
                        maxLength: 17,
                        minLength: 2,
                      })}
                    />
                {errors.chassis && errors.chassis?.type === "required" && (
                  <span className="error-msg">Ce champ est obligatoire.</span>
                )}
              {errors.chassis && errors.chassis?.type === "minLength" && (
                <span className="error-msg">
                  Ne peut pas être inférieur à deux caractères.
                </span>
              )}
              {errors.chassis && errors.chassis?.type === "maxLength" && (
                <span className="error-msg">
                  Ne peut pas être superieur à dix sept (17).
                </span>
              )}
                </label>
            </div>
            <div>
                <label>Mode d'immatriculation
                   <select name='typeOperation'
                    {...register("typeOperation", {
                      onChange: (e) => {
                        handleInput(e);
                      },
                      validate: (value) => value != "",
                    })}
                    value={paiementData.typeOperation}
                   >
                      <option value="">-</option>
                      <option value="Immatriculation">Immatriculation</option>
                      <option value="Réimmatriculation">Réimmatriculation</option>
                   </select>
                   {errors.typeOperation && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                </label>
            </div>
            <div>
                <label>Categorie du vehicule
                  <select
                    name="categorie_id"
                    {...register("categorie_id", {
                      onChange: (e) => {
                        handleInput(e);
                      },
                      validate: (value) => value != "",
                    })}
                    value={paiementData.categorie_id}
                  >
                    <option value="">-</option>
                    {elementsData?.categories && 
                      elementsData.categories.map((categorie) => {
                        return (
                          <option value={categorie.categorie_id} key={categorie.categorie_id}>{categorie.nomCategorie}</option>
                        )
                      })    
                    }
                  </select>
                  {errors.categorie && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                </label>
            </div>
          </div> 
          <div className='input-group'>
             <label>Téléphone
                <input type='text'  name='telephone'
                 value={paiementData.telephone}
                 {...register("telephone", {
                   onChange: (e) => {
                     handleInput(e);
                   },
                    required: true,
                    maxLength: 9,
                    minLength: 5,
                    pattern: /6[0-9]{8}$/g,
                 })}
                />
             {errors.telephone && errors.telephone?.type === "required" && (
                <span className="error-msg">Ce champ est obligatoire.</span>
              )}
              {errors.telephone && errors.telephone?.type === "minLength" && (
                <span className="error-msg">
                  Ne peut pas être inférieur à 5 caractères.
                </span>
              )}
              {errors.telephone && errors.telephone?.type === "maxLength" && (
                <span className="error-msg">
                  Ne peut pas être supérieur à 9 caractères.
                </span>
              )}
              {errors.telephone && errors.telephone.type === "pattern" && (
                  <span role="alert" className="error-msg">
                    Format invalide.ex: 620000000
                  </span>
              )}
             </label>
             <label></label>
             <label></label>
          </div>
          <div className="buttons">
              <button type="submit">Suivant</button>
          </div>
          </form>
         }
    </div>   
  )
}

export default StepOne