import React, { useState } from 'react'
import CartTableFC from '../CartTableFC/CartTableFC'
import Erreurs from '../Erreurs/Erreurs';
import Api from '../../services/Api';
import { useRecoilState } from 'recoil';
import { loadingState } from '../../recoil/atoms/loadingAtom';

function StepTwo({ paiement ,setPaiement, nextStep,prevStep,activeStep,setActiveStep}) {
 const [isLoading,setIsLoading] = useRecoilState(loadingState);
  const [ erreurs , setErreurs ] = useState();
  const api = new Api();
  const payer = async () => {
     setErreurs([]);setIsLoading(true);
       const response = await api.apiData("post",'/paiement/certificat/nouveau',paiement);
     setIsLoading(false);
     if( response.status !== 200){
        setErreurs(response?.errors);
     }
     else{
         setPaiement(response.data);
         setActiveStep( activeStep + 1 );
     }
   
  }
 
  return (
    <div className="step-component step-two">
       <h4>Paiement de certificat de document</h4>
       <div className="table">
          <CartTableFC paiement={paiement}/>
       </div>
       <Erreurs validation={erreurs} />
       <div className="buttons">
        <button className="secondary" onClick={prevStep}>
          Précedent
        </button>
        <button className="primary" onClick={payer}>
          Payer
        </button>
      </div>
    </div>   
  )
}

export default StepTwo